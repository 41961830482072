<div class="relative isolate pt-8">
  <div
    *ngIf="false"
    class="absolute inset-x-0 -top-40 -z-50 transform-gpu overflow-hidden blur-3xl sm:-top-80"
    aria-hidden="true">
    <div
      class="relative left-[calc(50%-11rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 rotate-[30deg] bg-gradient-to-tr from-[#ff80b5] to-[#9089fc] opacity-20 sm:left-[calc(50%-30rem)] sm:w-[72.1875rem]"
      style="
        clip-path: polygon(
          74.1% 44.1%,
          100% 61.6%,
          97.5% 26.9%,
          85.5% 0.1%,
          80.7% 2%,
          72.5% 32.5%,
          60.2% 62.4%,
          52.4% 68.1%,
          47.5% 58.3%,
          45.2% 34.5%,
          27.5% 76.7%,
          0.1% 64.9%,
          17.9% 100%,
          27.6% 76.8%,
          76.1% 97.7%,
          74.1% 44.1%
        );
      "></div>
  </div>
  <div class="pb-12 sm:pb-16 lg:pb-20">
    <div class="mx-auto max-w-7xl px-6 lg:px-8">
      <div class="max-w-2xl text-left">
        <h1 class="text-2xl font-bold tracking-tight text-white sm:text-4xl">
          <ng-content select="giga-portfolio-title"></ng-content>
        </h1>
        @if (siteLink() && siteLinkLabel()) {
          <div class="mt-2 flex items-center flex-row">
            <a [attr.href]="siteLink()" target="_blank" class="mr-2 h-[24px]"
              ><mat-icon>link</mat-icon></a
            >
            <a
              [attr.href]="siteLink()"
              target="_blank"
              class="underline underline-offset-4 leading-4"
              >{{ siteLinkLabel() }}</a
            >
          </div>
        }
      </div>
      @if (image()) {
        <img
          [attr.src]="image()"
          alt="App screenshot"
          width="2432"
          height="1442"
          class="mt-8 rounded-md bg-white/5 shadow-2xl ring-1 ring-white/10 sm:mt-12" />
      }

      <ng-content select="giga-portfolio-image"></ng-content>

      <div class="max-w-2xl text-left">
        <p class="mt-6 text-lg leading-8 text-gray-300">
          <ng-content select="giga-portfolio-description"></ng-content>
        </p>
      </div>
    </div>
  </div>
  <div
    *ngIf="true"
    class="absolute inset-x-0 top-[calc(100%-13rem)] -z-10 transform-gpu overflow-hidden blur-3xl sm:top-[calc(100%-30rem)]"
    aria-hidden="true">
    <div
      class="relative left-[calc(50%+3rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 bg-gradient-to-tr from-[#ff80b5] to-[#9089fc] opacity-20 sm:left-[calc(50%+36rem)] sm:w-[72.1875rem]"
      style="
        clip-path: polygon(
          74.1% 44.1%,
          100% 61.6%,
          97.5% 26.9%,
          85.5% 0.1%,
          80.7% 2%,
          72.5% 32.5%,
          60.2% 62.4%,
          52.4% 68.1%,
          47.5% 58.3%,
          45.2% 34.5%,
          27.5% 76.7%,
          0.1% 64.9%,
          17.9% 100%,
          27.6% 76.8%,
          76.1% 97.7%,
          74.1% 44.1%
        );
      "></div>
  </div>
</div>
