<div class="mx-auto max-w-7xl px-6 lg:px-8">
  <div class="mx-auto max-w-2xl sm:text-center">
    <h2 class="text-base font-semibold leading-7 text-indigo-400">Live Data</h2>
    <p class="mt-2 text-3xl font-bold tracking-tight text-white sm:text-4xl">
      Firebase or Google Cloud Platform Integration.
    </p>
    <p class="mt-6 text-lg leading-8 text-gray-300">
      Create real-time communication applications with Firebase, or data analytics apps with Google
      Cloud Platform.
    </p>
  </div>
</div>
<div class="relative overflow-hidden pt-16">
  <div class="mx-auto max-w-7xl px-6 lg:px-8">
    <img
      src="assets/firebase-hosting.png"
      alt="App screenshot"
      class="mb-[-12%] rounded-xl shadow-2xl ring-1 ring-white/10"
      width="2880"
      height="1800" />
    <div class="relative" aria-hidden="true">
      <div class="absolute -inset-x-20 bottom-0 bg-gradient-to-t from-gray-900 pt-[7%]"></div>
    </div>
  </div>
</div>
<div class="mx-auto mt-16 max-w-7xl px-6 sm:mt-20 md:mt-24 lg:px-8">
  <dl
    class="mx-auto grid max-w-2xl grid-cols-1 gap-x-6 gap-y-10 text-base leading-7 text-gray-300 sm:grid-cols-2 lg:mx-0 lg:max-w-none lg:grid-cols-3 lg:gap-x-8 lg:gap-y-16">
    <div class="relative pl-9">
      <dt class="inline font-semibold text-white">
        <svg
          class="absolute top-1 left-1 h-5 w-5 text-indigo-500"
          viewBox="0 0 20 20"
          fill="currentColor"
          aria-hidden="true">
          <path
            fill-rule="evenodd"
            d="M5.5 17a4.5 4.5 0 01-1.44-8.765 4.5 4.5 0 018.302-3.046 3.5 3.5 0 014.504 4.272A4 4 0 0115 17H5.5zm3.75-2.75a.75.75 0 001.5 0V9.66l1.95 2.1a.75.75 0 101.1-1.02l-3.25-3.5a.75.75 0 00-1.1 0l-3.25 3.5a.75.75 0 101.1 1.02l1.95-2.1v4.59z"
            clip-rule="evenodd" />
        </svg>
        Hosting.
      </dt>
      <dd class="inline">
        Hosting to Firebase for real-time communication, iOT applications. Or host to Google Clout
        Platform.
      </dd>
    </div>

    <div class="relative pl-9">
      <dt class="inline font-semibold text-white">
        <svg
          class="absolute top-1 left-1 h-5 w-5 text-indigo-500"
          viewBox="0 0 20 20"
          fill="currentColor"
          aria-hidden="true">
          <path
            fill-rule="evenodd"
            d="M10 1a4.5 4.5 0 00-4.5 4.5V9H5a2 2 0 00-2 2v6a2 2 0 002 2h10a2 2 0 002-2v-6a2 2 0 00-2-2h-.5V5.5A4.5 4.5 0 0010 1zm3 8V5.5a3 3 0 10-6 0V9h6z"
            clip-rule="evenodd" />
        </svg>
        Authentication.
      </dt>
      <dd class="inline">
        Use most authentication provides like Apple, emai, Google, Facebook, etc using Firebase
        authentication.
      </dd>
    </div>

    <div class="relative pl-9">
      <dt class="inline font-semibold text-white">
        <svg
          class="absolute top-1 left-1 h-5 w-5 text-indigo-500"
          viewBox="0 0 20 20"
          fill="currentColor"
          aria-hidden="true">
          <path
            fill-rule="evenodd"
            d="M15.312 11.424a5.5 5.5 0 01-9.201 2.466l-.312-.311h2.433a.75.75 0 000-1.5H3.989a.75.75 0 00-.75.75v4.242a.75.75 0 001.5 0v-2.43l.31.31a7 7 0 0011.712-3.138.75.75 0 00-1.449-.39zm1.23-3.723a.75.75 0 00.219-.53V2.929a.75.75 0 00-1.5 0V5.36l-.31-.31A7 7 0 003.239 8.188a.75.75 0 101.448.389A5.5 5.5 0 0113.89 6.11l.311.31h-2.432a.75.75 0 000 1.5h4.243a.75.75 0 00.53-.219z"
            clip-rule="evenodd" />
        </svg>
        Real-time data.
      </dt>
      <dd class="inline">
        Firebase Firestore provides real-time data. Create chat apps, real-time user content
        integration, and iOT applications.
      </dd>
    </div>

    <div class="relative pl-9">
      <dt class="inline font-semibold text-white">
        <svg
          class="absolute top-1 left-1 h-5 w-5 text-indigo-500"
          width="20"
          height="20"
          viewBox="0 0 19 20"
          fill="currentColor"
          xmlns="http://www.w3.org/2000/svg">
          <path
            d="M9.05556 20V17.6667C8 17.4815 7.13426 17.0787 6.45833 16.4583C5.78241 15.838 5.2963 15.0556 5 14.1111L6.55556 13.4722C6.87037 14.3611 7.32407 15.0231 7.91667 15.4583C8.50926 15.8935 9.22222 16.1111 10.0556 16.1111C10.9444 16.1111 11.6759 15.8889 12.25 15.4444C12.8241 15 13.1111 14.3889 13.1111 13.6111C13.1111 12.7963 12.8565 12.1667 12.3472 11.7222C11.838 11.2778 10.8796 10.8241 9.47222 10.3611C8.13889 9.93519 7.14352 9.37037 6.48611 8.66667C5.8287 7.96296 5.5 7.08333 5.5 6.02778C5.5 5.00926 5.8287 4.15741 6.48611 3.47222C7.14352 2.78704 8 2.39815 9.05556 2.30556V0H10.7222V2.30556C11.5556 2.39815 12.2731 2.6713 12.875 3.125C13.4769 3.5787 13.9352 4.15741 14.25 4.86111L12.6944 5.52778C12.4352 4.93519 12.088 4.50463 11.6528 4.23611C11.2176 3.96759 10.6481 3.83333 9.94444 3.83333C9.09259 3.83333 8.41667 4.02778 7.91667 4.41667C7.41667 4.80556 7.16667 5.33333 7.16667 6C7.16667 6.7037 7.44444 7.27315 8 7.70833C8.55556 8.14352 9.58333 8.59259 11.0833 9.05556C12.3426 9.44444 13.2731 10.0046 13.875 10.7361C14.4769 11.4676 14.7778 12.3889 14.7778 13.5C14.7778 14.6667 14.4352 15.6065 13.75 16.3194C13.0648 17.0324 12.0556 17.4907 10.7222 17.6944V20H9.05556Z"
            fill="currentColor" />
        </svg>

        <!--              <svg-->
        <!--                class="absolute top-1 left-1 h-5 w-5 text-indigo-500"-->
        <!--                viewBox="0 0 20 20"-->
        <!--                fill="currentColor"-->
        <!--                aria-hidden="true">-->
        <!--                <path-->
        <!--                  fill-rule="evenodd"-->
        <!--                  d="M10 2.5c-1.31 0-2.526.386-3.546 1.051a.75.75 0 01-.82-1.256A8 8 0 0118 9a22.47 22.47 0 01-1.228 7.351.75.75 0 11-1.417-.49A20.97 20.97 0 0016.5 9 6.5 6.5 0 0010 2.5zM4.333 4.416a.75.75 0 01.218 1.038A6.466 6.466 0 003.5 9a7.966 7.966 0 01-1.293 4.362.75.75 0 01-1.257-.819A6.466 6.466 0 002 9c0-1.61.476-3.11 1.295-4.365a.75.75 0 011.038-.219zM10 6.12a3 3 0 00-3.001 3.041 11.455 11.455 0 01-2.697 7.24.75.75 0 01-1.148-.965A9.957 9.957 0 005.5 9c0-.028.002-.055.004-.082a4.5 4.5 0 018.996.084V9.15l-.005.297a.75.75 0 11-1.5-.034c.003-.11.004-.219.005-.328a3 3 0 00-3-2.965zm0 2.13a.75.75 0 01.75.75c0 3.51-1.187 6.745-3.181 9.323a.75.75 0 11-1.186-.918A13.687 13.687 0 009.25 9a.75.75 0 01.75-.75zm3.529 3.698a.75.75 0 01.584.885 18.883 18.883 0 01-2.257 5.84.75.75 0 11-1.29-.764 17.386 17.386 0 002.078-5.377.75.75 0 01.885-.584z"-->
        <!--                  clip-rule="evenodd" />-->
        <!--              </svg>-->
        Payments.
      </dt>
      <dd class="inline">Integrate Stripe, iOS, Google Playstore payments.</dd>
    </div>

    <div class="relative pl-9">
      <dt class="inline font-semibold text-white">
        <svg
          class="absolute top-1 left-1 h-5 w-5 text-indigo-500"
          viewBox="0 0 20 20"
          fill="currentColor"
          aria-hidden="true">
          <path
            fill-rule="evenodd"
            d="M7.84 1.804A1 1 0 018.82 1h2.36a1 1 0 01.98.804l.331 1.652a6.993 6.993 0 011.929 1.115l1.598-.54a1 1 0 011.186.447l1.18 2.044a1 1 0 01-.205 1.251l-1.267 1.113a7.047 7.047 0 010 2.228l1.267 1.113a1 1 0 01.206 1.25l-1.18 2.045a1 1 0 01-1.187.447l-1.598-.54a6.993 6.993 0 01-1.929 1.115l-.33 1.652a1 1 0 01-.98.804H8.82a1 1 0 01-.98-.804l-.331-1.652a6.993 6.993 0 01-1.929-1.115l-1.598.54a1 1 0 01-1.186-.447l-1.18-2.044a1 1 0 01.205-1.251l1.267-1.114a7.05 7.05 0 010-2.227L1.821 7.773a1 1 0 01-.206-1.25l1.18-2.045a1 1 0 011.187-.447l1.598.54A6.993 6.993 0 017.51 3.456l.33-1.652zM10 13a3 3 0 100-6 3 3 0 000 6z"
            clip-rule="evenodd" />
        </svg>
        Powerful API.
      </dt>
      <dd class="inline">Integrate with Google Cloud Platform ( GCP ).</dd>
    </div>

    <div class="relative pl-9">
      <dt class="inline font-semibold text-white">
        <!--              <svg-->
        <!--                class="absolute top-1 left-1 h-5 w-5 text-indigo-500"-->
        <!--                viewBox="0 0 20 20"-->
        <!--                fill="currentColor"-->
        <!--                aria-hidden="true">-->
        <!--                <path-->
        <!--                  d="M4.632 3.533A2 2 0 016.577 2h6.846a2 2 0 011.945 1.533l1.976 8.234A3.489 3.489 0 0016 11.5H4c-.476 0-.93.095-1.344.267l1.976-8.234z" />-->
        <!--                <path-->
        <!--                  fill-rule="evenodd"-->
        <!--                  d="M4 13a2 2 0 100 4h12a2 2 0 100-4H4zm11.24 2a.75.75 0 01.75-.75H16a.75.75 0 01.75.75v.01a.75.75 0 01-.75.75h-.01a.75.75 0 01-.75-.75V15zm-2.25-.75a.75.75 0 00-.75.75v.01c0 .414.336.75.75.75H13a.75.75 0 00.75-.75V15a.75.75 0 00-.75-.75h-.01z"-->
        <!--                  clip-rule="evenodd" />-->
        <!--              </svg>-->
        <svg
          class="absolute top-1 left-1 h-5 w-5 text-indigo-500"
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="currentColor"
          xmlns="http://www.w3.org/2000/svg">
          <g clip-path="url(#clip0_114_51)">
            <path
              d="M0 20V16.6667L2.5 14.1667V20H0ZM4.375 20V12.2917L6.66667 10L6.875 10.2083V20H4.375ZM8.75 20V12.0833L11.25 14.5833V20H8.75ZM13.125 20V13.5417L15.625 11.0417V20H13.125ZM17.5 20V9.16667L20 6.66667V20H17.5ZM0 13.5417V10L6.66667 3.33333L11.6667 8.33333L20 0V3.54167L11.6667 11.875L6.66667 6.875L0 13.5417Z"
              fill="currentColor" />
          </g>
          <defs>
            <clipPath id="clip0_114_51">
              <rect width="20" height="20" fill="white" />
            </clipPath>
          </defs>
        </svg>

        Google Analytics.
      </dt>
      <dd class="inline">See your customer journey through analytics.</dd>
    </div>
  </dl>
</div>
