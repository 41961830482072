<svg
  class="absolute inset-0 -z-10 h-full w-full stroke-white/10 [mask-image:radial-gradient(100%_100%_at_top_right,white,transparent)]"
  aria-hidden="true">
  <defs>
    <pattern
      id="1d4240dd-898f-445f-932d-e2872fd12de3"
      width="200"
      height="200"
      x="50%"
      y="0"
      patternUnits="userSpaceOnUse">
      <path d="M.5 200V.5H200" fill="none" />
    </pattern>
  </defs>
  <svg x="50%" y="0" class="overflow-visible fill-gray-800/20">
    <path
      d="M-200 0h201v201h-201Z M600 0h201v201h-201Z M-400 600h201v201h-201Z M200 800h201v201h-201Z"
      stroke-width="0" />
  </svg>
  <rect
    width="100%"
    height="100%"
    stroke-width="0"
    fill="url(#1d4240dd-898f-445f-932d-e2872fd12de3)" />
</svg>
<div
  class="absolute inset-x-0 top-10 -z-10 flex transform-gpu justify-center overflow-hidden blur-3xl">
  <svg viewBox="0 0 1108 632" aria-hidden="true" class="w-[69.25rem] flex-none">
    <path
      fill="url(#175c433f-44f6-4d59-93f0-c5c51ad5566d)"
      fill-opacity=".2"
      d="M235.233 402.609 57.541 321.573.83 631.05l234.404-228.441 320.018 145.945c-65.036-115.261-134.286-322.756 109.01-230.655C968.382 433.026 1031 651.247 1092.23 459.36c48.98-153.51-34.51-321.107-82.37-385.717L810.952 324.222 648.261.088 235.233 402.609Z" />
    <defs>
      <linearGradient
        id="175c433f-44f6-4d59-93f0-c5c51ad5566d"
        x1="1220.59"
        x2="-85.053"
        y1="432.766"
        y2="638.714"
        gradientUnits="userSpaceOnUse">
        <stop stop-color="#4F46E5" />
        <stop offset="1" stop-color="#80CAFF" />
      </linearGradient>
    </defs>
  </svg>
</div>
<div class="mx-auto max-w-2xl text-center">
  <h2 class="text-3xl font-bold tracking-tight text-white sm:text-4xl">
    <!--          Create. Innovate.<br />Start your project today.-->
    Start you project<br />with a scalable architecture.
  </h2>
  <p class="mx-auto mt-6 max-w-xl text-lg leading-8 text-gray-300">
    <!--          Let's build your idea.-->
    Software technologies that scale with your ideas.
  </p>
  <!--    <div class="mt-10 flex items-center justify-center gap-x-6">-->
  <!--      &lt;!&ndash;          <a&ndash;&gt;-->
  <!--      &lt;!&ndash;            [routerLink]="'contact'"&ndash;&gt;-->
  <!--      &lt;!&ndash;            class="rounded-md bg-indigo-500 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-white"&ndash;&gt;-->
  <!--      &lt;!&ndash;            >Get an estimate</a&ndash;&gt;-->
  <!--      &lt;!&ndash;          >&ndash;&gt;-->
  <!--      <a [routerLink]="'prices'" class="text-sm font-semibold leading-6 text-white"-->
  <!--      >See prices <span aria-hidden="true">→</span></a-->
  <!--      >-->
  <!--    </div>-->
</div>
