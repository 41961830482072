<div class="mx-auto max-w-2xl text-center">
  <h2 class="text-base font-semibold leading-7 text-indigo-400">Cross-platform</h2>
  <p class="mt-2 text-3xl font-bold tracking-tight text-white sm:text-4xl">
    Develop and deploy across platforms.
  </p>
  <p class="mt-6 text-lg leading-8 text-gray-300">
    Using Nx to develop web, desktop, and mobile in one monorepo.
  </p>
</div>
<div class="mx-auto mt-16 max-w-2xl sm:mt-20 lg:mt-24 lg:max-w-none">
  <dl class="grid max-w-xl grid-cols-1 gap-x-8 gap-y-16 lg:max-w-none lg:grid-cols-3">
    <div class="flex flex-col">
      <dt class="text-base font-semibold leading-7 text-white">
        <div class="mb-6 flex h-10 w-10 items-center justify-center rounded-lg">
          <svg
            width="40"
            height="40"
            viewBox="0 0 40 40"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_112_30)">
              <path
                d="M20.0006 29.9996C25.5238 29.9996 30.0012 25.5222 30.0012 19.999C30.0012 14.4758 25.5238 9.99841 20.0006 9.99841C14.4774 9.99841 10 14.4758 10 19.999C10 25.5222 14.4774 29.9996 20.0006 29.9996Z"
                fill="white" />
              <path
                d="M11.3398 25.0015L2.6804 10.0031C0.924569 13.0432 0.000121136 16.4921 1.19041e-08 20.0029C-0.000121113 23.5137 0.924092 26.9627 2.67971 30.003C4.43533 33.0433 6.96047 35.5679 10.0012 37.3227C13.042 39.0776 16.4911 40.0009 20.0019 40L28.6613 25.0015V24.9989C27.7842 26.5199 26.5221 27.7831 25.0019 28.6614C23.4817 29.5398 21.757 30.0023 20.0013 30.0026C18.2456 30.0028 16.5207 29.5408 15.0002 28.6629C13.4798 27.785 12.2173 26.5222 11.3398 25.0015Z"
                fill="url(#paint0_linear_112_30)" />
              <path
                d="M28.6605 25.0009L20.0012 39.9993C23.512 39.9999 26.961 39.076 30.0015 37.3207C33.042 35.5654 35.5668 33.0405 37.322 30C39.0772 26.9593 40.0009 23.5103 40.0003 19.9994C39.9997 16.4886 39.0747 13.0399 37.3184 9.99994H19.9997L19.9976 10.0013C21.7533 10.0004 23.4783 10.4619 24.9991 11.3392C26.5198 12.2166 27.7828 13.4789 28.6609 14.9993C29.5389 16.5196 30.0012 18.2444 30.0012 20.0001C30.0011 21.7558 29.5386 23.4805 28.6605 25.0009Z"
                fill="url(#paint1_linear_112_30)" />
              <path
                d="M20.0004 27.9183C24.3729 27.9183 27.9175 24.3737 27.9175 20.0011C27.9175 15.6286 24.3729 12.084 20.0004 12.084C15.6278 12.084 12.0833 15.6286 12.0833 20.0011C12.0833 24.3737 15.6278 27.9183 20.0004 27.9183Z"
                fill="#1A73E8" />
              <path
                d="M20.0003 10.0006H37.3191C35.564 6.9599 33.0395 4.43484 29.9991 2.67934C26.9587 0.923838 23.5097 -0.000249126 19.9989 5.03787e-08C16.4881 0.000249227 13.0392 0.924823 9.99912 2.68076C6.95894 4.43669 4.43472 6.96212 2.68018 10.003L11.3395 25.0015L11.3417 25.0027C10.4631 23.4826 10.0003 21.758 9.99969 20.0023C9.99912 18.2466 10.4609 16.5217 11.3386 15.0011C12.2161 13.4805 13.4788 12.2177 14.9992 11.34C16.5198 10.4621 18.2446 10.0002 20.0003 10.0006Z"
                fill="url(#paint2_linear_112_30)" />
            </g>
            <defs>
              <linearGradient
                id="paint0_linear_112_30"
                x1="22.1656"
                y1="38.7508"
                x2="4.84615"
                y2="8.75269"
                gradientUnits="userSpaceOnUse">
                <stop stop-color="#1E8E3E" />
                <stop offset="1" stop-color="#34A853" />
              </linearGradient>
              <linearGradient
                id="paint1_linear_112_30"
                x1="17.0829"
                y1="40.0524"
                x2="34.4023"
                y2="10.0542"
                gradientUnits="userSpaceOnUse">
                <stop stop-color="#FCC934" />
                <stop offset="1" stop-color="#FBBC04" />
              </linearGradient>
              <linearGradient
                id="paint2_linear_112_30"
                x1="2.68018"
                y1="12.5013"
                x2="37.3191"
                y2="12.5013"
                gradientUnits="userSpaceOnUse">
                <stop stop-color="#D93025" />
                <stop offset="1" stop-color="#EA4335" />
              </linearGradient>
              <clipPath id="clip0_112_30">
                <rect width="40.0005" height="40" fill="white" />
              </clipPath>
            </defs>
          </svg>
        </div>
        Web
      </dt>
      <dd class="mt-1 flex flex-auto flex-col text-base leading-7 text-gray-300">
        <p class="flex-auto">
          Developing with Angular, Angular Material, TailwindCSS, NgRx and D3 to make rich and
          performant applications.
        </p>
        <!--              <p class="mt-6">-->
        <!--                <a-->
        <!--                  href="#"-->
        <!--                  class="text-sm font-semibold leading-6 text-indigo-400"-->
        <!--                  >Learn more <span aria-hidden="true">→</span></a-->
        <!--                >-->
        <!--              </p>-->
      </dd>
    </div>

    <div class="flex flex-col">
      <dt class="text-base font-semibold leading-7 text-white">
        <div class="mb-6 flex h-10 w-10 items-center justify-center rounded-lg bg-indigo-500">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            height="24"
            viewBox="0 96 960 960"
            fill="currentColor"
            width="24">
            <path
              d="M280 1016q-33 0-56.5-23.5T200 936V216q0-33 23.5-56.5T280 136h400q33 0 56.5 23.5T760 216v720q0 33-23.5 56.5T680 1016H280Zm200-100q17 0 28.5-11.5T520 876q0-17-11.5-28.5T480 836q-17 0-28.5 11.5T440 876q0 17 11.5 28.5T480 916ZM280 736h400V336H280v400Z" />
          </svg>
        </div>
        Mobile
      </dt>
      <dd class="mt-1 flex flex-auto flex-col text-base leading-7 text-gray-300">
        <p class="flex-auto">Using CapacitorJS to deploy to iOS and Android platforms.</p>
        <!--              <p class="mt-6">-->
        <!--                <a-->
        <!--                  href="#"-->
        <!--                  class="text-sm font-semibold leading-6 text-indigo-400"-->
        <!--                  >Learn more <span aria-hidden="true">→</span></a-->
        <!--                >-->
        <!--              </p>-->
      </dd>
    </div>

    <div class="flex flex-col">
      <dt class="text-base font-semibold leading-7 text-white">
        <div class="mb-6 flex h-10 w-10 items-center justify-center rounded-lg">
          <svg
            width="40"
            height="40"
            viewBox="0 0 40 40"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_112_37)">
              <path
                d="M20 40C31.0457 40 40 31.0457 40 20C40 8.9543 31.0457 0 20 0C8.9543 0 0 8.9543 0 20C0 31.0457 8.9543 40 20 40Z"
                fill="#2F3242" />
              <path
                d="M16.061 12.3446C16.3666 12.4004 16.6596 12.1978 16.7154 11.8922C16.7711 11.5866 16.5686 11.2936 16.2629 11.2379L16.061 12.3446ZM9.04352 19.0424C9.19786 19.312 9.54152 19.4055 9.81114 19.2512C10.0808 19.0968 10.1742 18.7532 10.0199 18.4835L9.04352 19.0424ZM11.7792 20.858C11.5703 20.6281 11.2145 20.6111 10.9846 20.82C10.7547 21.0289 10.7376 21.3846 10.9465 21.6146L11.7792 20.858ZM30.2877 27.9734C30.5442 27.7982 30.6102 27.4482 30.435 27.1917C30.2598 26.9352 29.9098 26.8692 29.6532 27.0444L30.2877 27.9734ZM16.2629 11.2379C14.5222 10.9203 12.9015 10.9052 11.5543 11.2305C10.2046 11.5564 9.08877 12.2367 8.45496 13.3363L9.42961 13.8982C9.86539 13.1422 10.6702 12.6013 11.8184 12.3241C12.969 12.0462 14.4233 12.0459 16.061 12.3446L16.2629 11.2379ZM8.45496 13.3363C7.51342 14.9697 7.89939 17.0437 9.04352 19.0424L10.0199 18.4835C8.95558 16.6243 8.78399 15.0182 9.42961 13.8982L8.45496 13.3363ZM10.9465 21.6146C12.387 23.2 14.2862 24.7298 16.5149 26.0187L17.0781 25.0447C14.94 23.8084 13.1343 22.3495 11.7792 20.858L10.9465 21.6146ZM16.5149 26.0187C19.17 27.5539 21.8895 28.5139 24.2922 28.8627C26.6747 29.2087 28.8357 28.965 30.2877 27.9734L29.6532 27.0444C28.5392 27.8052 26.7157 28.0778 24.4539 27.7494C22.2123 27.424 19.6278 26.5191 17.0781 25.0447L16.5149 26.0187ZM32.9247 26.5337C32.9247 25.448 32.0456 24.5665 30.9597 24.5665V25.6915C31.4228 25.6915 31.7997 26.0677 31.7997 26.5337H32.9247ZM30.9597 24.5665C29.8736 24.5665 28.9947 25.448 28.9947 26.5337H30.1197C30.1197 26.0677 30.4966 25.6915 30.9597 25.6915V24.5665ZM28.9947 26.5337C28.9947 27.6193 29.8736 28.5008 30.9597 28.5008V27.3758C30.4966 27.3758 30.1197 26.9996 30.1197 26.5337H28.9947ZM30.9597 28.5008C32.0456 28.5008 32.9247 27.6193 32.9247 26.5337H31.7997C31.7997 26.9996 31.4228 27.3758 30.9597 27.3758V28.5008Z"
                fill="#A2ECFB" />
              <path
                d="M28.5896 20.4319C28.3891 20.6691 28.4189 21.024 28.6562 21.2246C28.8934 21.4251 29.2483 21.3953 29.4488 21.1581L28.5896 20.4319ZM26.4083 11.0129C26.0977 11.0088 25.8426 11.2574 25.8385 11.568C25.8345 11.8786 26.083 12.1337 26.3937 12.1378L26.4083 11.0129ZM23.4188 12.4625C23.7226 12.3974 23.9159 12.0983 23.8508 11.7945C23.7856 11.4908 23.4865 11.2974 23.1828 11.3626L23.4188 12.4625ZM7.98394 25.2827C8.02897 25.5901 8.31466 25.8027 8.62206 25.7577C8.92944 25.7127 9.1421 25.427 9.09706 25.1196L7.98394 25.2827ZM29.4488 21.1581C30.5885 19.8096 31.4077 18.4147 31.7973 17.0869C32.1877 15.7566 32.1561 14.4522 31.5236 13.3551L30.549 13.917C30.984 14.6716 31.0499 15.6381 30.7178 16.7701C30.3849 17.9045 29.6618 19.1632 28.5896 20.4319L29.4488 21.1581ZM31.5236 13.3551C30.5973 11.7481 28.6587 11.0422 26.4083 11.0129L26.3937 12.1378C28.4788 12.1649 29.9143 12.8158 30.549 13.917L31.5236 13.3551ZM23.1828 11.3626C21.0737 11.8152 18.7773 12.7025 16.5257 14.0046L17.0888 14.9784C19.249 13.7293 21.4357 12.8881 23.4188 12.4625L23.1828 11.3626ZM16.5257 14.0046C13.7925 15.585 11.552 17.5298 10.0464 19.4977C8.55528 21.4469 7.72322 23.5036 7.98394 25.2827L9.09706 25.1196C8.90106 23.7821 9.52319 22.0332 10.94 20.1813C12.3423 18.3482 14.4636 16.4965 17.0888 14.9784L16.5257 14.0046Z"
                fill="#A2ECFB" />
              <path
                d="M15.3384 27.2592C15.2338 26.9667 14.9119 26.8143 14.6194 26.9189C14.3268 27.0235 14.1745 27.3455 14.2791 27.638L15.3384 27.2592ZM24.5512 30.133C24.7111 29.8666 24.6247 29.5211 24.3584 29.3612C24.092 29.2013 23.7465 29.2877 23.5866 29.554L24.5512 30.133ZM24.8658 26.6186C24.7732 26.9151 24.9385 27.2306 25.235 27.3232C25.5315 27.4159 25.847 27.2506 25.9396 26.9541L24.8658 26.6186ZM21.6114 7.04066C21.3275 6.91441 20.9951 7.04219 20.8688 7.32603C20.7426 7.60991 20.8704 7.94234 21.1542 8.06856L21.6114 7.04066ZM14.2791 27.638C14.8749 29.3044 15.6719 30.7158 16.6261 31.7203C17.5821 32.7262 18.7276 33.3534 19.995 33.3534V32.2284C19.1246 32.2284 18.2553 31.8016 17.4417 30.9453C16.6264 30.0872 15.8991 28.8274 15.3384 27.2592L14.2791 27.638ZM19.995 33.3534C21.8343 33.3534 23.4039 32.0444 24.5512 30.133L23.5866 29.554C22.5245 31.3234 21.2539 32.2284 19.995 32.2284V33.3534ZM25.9396 26.9541C26.571 24.9332 26.9324 22.5572 26.9324 20.021H25.8074C25.8074 22.4542 25.4602 24.7162 24.8658 26.6186L25.9396 26.9541ZM26.9324 20.021C26.9324 16.9107 26.3888 14.0381 25.4677 11.7657C24.5547 9.51362 23.2325 7.76159 21.6114 7.04066L21.1542 8.06856C22.3865 8.61659 23.5578 10.0489 24.4251 12.1883C25.2841 14.3074 25.8074 17.0334 25.8074 20.021H26.9324Z"
                fill="#A2ECFB" />
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M28.9946 26.5337C28.9946 25.4481 29.8735 24.5665 30.9596 24.5665C32.0458 24.5665 32.9246 25.4481 32.9246 26.5337C32.9246 27.6192 32.0458 28.5008 30.9596 28.5008C29.8735 28.5008 28.9946 27.6193 28.9946 26.5337ZM30.9596 25.6915C30.4965 25.6915 30.1196 26.0677 30.1196 26.5337C30.1196 26.9996 30.4965 27.3758 30.9596 27.3758C31.4228 27.3758 31.7996 26.9996 31.7996 26.5337C31.7996 26.0677 31.4228 25.6915 30.9596 25.6915Z"
                fill="#A2ECFB" />
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M7.03931 26.5337C7.03931 25.4481 7.91821 24.5665 9.00428 24.5665C10.0903 24.5665 10.9693 25.448 10.9693 26.5337C10.9693 27.6193 10.0903 28.5008 9.00428 28.5008C7.91821 28.5008 7.03931 27.6193 7.03931 26.5337ZM9.00428 25.6915C8.54121 25.6915 8.16431 26.0677 8.16431 26.5337C8.16431 26.9996 8.54121 27.3758 9.00428 27.3758C9.46735 27.3758 9.84427 26.9996 9.84427 26.5337C9.84427 26.0677 9.46735 25.6915 9.00428 25.6915Z"
                fill="#A2ECFB" />
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M19.9948 6.40894C19.5317 6.40894 19.1548 6.78516 19.1548 7.25112C19.1548 7.71708 19.5317 8.09331 19.9948 8.09331C20.4578 8.09331 20.8347 7.71708 20.8347 7.25112C20.8347 6.78516 20.4578 6.40894 19.9948 6.40894ZM18.0298 7.25112C18.0298 6.16551 18.9087 5.28394 19.9948 5.28394C21.0808 5.28394 21.9597 6.16551 21.9597 7.25112C21.9597 8.33674 21.0808 9.21831 19.9948 9.21831C18.9087 9.21831 18.0298 8.33674 18.0298 7.25112Z"
                fill="#A2ECFB" />
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M20.2657 21.2768C19.5732 21.4266 18.8909 20.9858 18.741 20.2923C18.5917 19.5987 19.0315 18.9154 19.724 18.7653C20.4164 18.6154 21.0987 19.0562 21.2486 19.7497C21.3982 20.4433 20.9581 21.1266 20.2657 21.2768Z"
                fill="#A2ECFB" />
            </g>
            <defs>
              <clipPath id="clip0_112_37">
                <rect width="40" height="40" fill="white" />
              </clipPath>
            </defs>
          </svg>
        </div>
        Desktop
      </dt>
      <dd class="mt-1 flex flex-auto flex-col text-base leading-7 text-gray-300">
        <p class="flex-auto">Using ElectronJS to build Mac and Windows desktop applications.</p>
        <!--              <p class="mt-6">-->
        <!--                <a-->
        <!--                  href="#"-->
        <!--                  class="text-sm font-semibold leading-6 text-indigo-400"-->
        <!--                  >Learn more <span aria-hidden="true">→</span></a-->
        <!--                >-->
        <!--              </p>-->
      </dd>
    </div>
  </dl>
</div>
