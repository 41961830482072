import {Component, input, ViewEncapsulation} from '@angular/core';
import {CommonModule} from '@angular/common';

@Component({
  selector: 'giga-section-title',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './section-title.component.html',
  styleUrl: './section-title.component.scss',
  encapsulation: ViewEncapsulation.None
})
export class SectionTitleComponent {
  id = input('');
}
