<div class="bg-gray-900 h-[calc(100%+13rem)]">
  <main>
    <!-- Hero section -->
    <div class="relative isolate overflow-hidden">
      <svg
        class="absolute inset-0 -z-10 h-full w-full stroke-white/10 [mask-image:radial-gradient(100%_100%_at_top_right,white,transparent)]"
        aria-hidden="true">
        <defs>
          <pattern
            id="983e3e4c-de6d-4c3f-8d64-b9761d1534cc"
            width="200"
            height="200"
            x="50%"
            y="-1"
            patternUnits="userSpaceOnUse">
            <path d="M.5 200V.5H200" fill="none" />
          </pattern>
        </defs>
        <svg x="50%" y="-1" class="overflow-visible fill-gray-800/20">
          <path
            d="M-200 0h201v201h-201Z M600 0h201v201h-201Z M-400 600h201v201h-201Z M200 800h201v201h-201Z"
            stroke-width="0" />
        </svg>
        <rect
          width="100%"
          height="100%"
          stroke-width="0"
          fill="url(#983e3e4c-de6d-4c3f-8d64-b9761d1534cc)" />
      </svg>
      <svg
        viewBox="0 0 1108 632"
        aria-hidden="true"
        class="absolute top-10 left-[calc(50%-4rem)] -z-10 w-[69.25rem] max-w-none transform-gpu blur-3xl sm:left-[calc(50%-18rem)] lg:left-48 lg:top-[calc(50%-30rem)] xl:left-[calc(50%-24rem)]">
        <path
          fill="url(#175c433f-44f6-4d59-93f0-c5c51ad5566d)"
          fill-opacity=".2"
          d="M235.233 402.609 57.541 321.573.83 631.05l234.404-228.441 320.018 145.945c-65.036-115.261-134.286-322.756 109.01-230.655C968.382 433.026 1031 651.247 1092.23 459.36c48.98-153.51-34.51-321.107-82.37-385.717L810.952 324.222 648.261.088 235.233 402.609Z" />
        <defs>
          <linearGradient
            id="175c433f-44f6-4d59-93f0-c5c51ad5566d"
            x1="1220.59"
            x2="-85.053"
            y1="432.766"
            y2="638.714"
            gradientUnits="userSpaceOnUse">
            <stop stop-color="#4F46E5" />
            <stop offset="1" stop-color="#80CAFF" />
          </linearGradient>
        </defs>
      </svg>
      <div class="mx-auto max-w-7xl px-6 pt-10 pb-24 sm:pb-40 lg:flex lg:px-8 lg:pt-40">
        <div class="mx-auto max-w-2xl flex-shrink-0 lg:mx-0 lg:max-w-xl lg:pt-8">
          <!--          <giga-logo-name></giga-logo-name>-->

          <!--          <img-->
          <!--            class="h-11"-->
          <!--            src="https://tailwindui.com/img/logos/mark.svg?color=indigo&shade=500"-->
          <!--            alt="Your Company" />-->
          <!--          <div class="mt-24 sm:mt-32 lg:mt-16">-->

          <h1 class="text-4xl font-bold tracking-tight text-white sm:text-6xl">Jerry Orta</h1>
          <p class="mt-6 text-lg leading-8 text-indigo-400 ont-bold">Angular Architect.</p>
          <p class="mt-6 text-lg leading-8 text-gray-300">Startup and enterprise experience.</p>
          <p class="mt-6 text-lg leading-8 text-gray-300">
            Building apps for Web, Mobile, and Desktop.
          </p>
          <p class="mt-6 text-lg leading-8 text-gray-300">
            Developing scalable nx monorepo front-end architectures.
          </p>

          <giga-page-link
            class="mt-10"
            [route]="'/skills'"
            [pillTitle]="'See Skills and Experience'"
            [linkTitle]="'See Skills and Experience'"></giga-page-link>

          <giga-page-link
            class="mt-10"
            [href]="'#frameworks'"
            [pillTitle]="'Frameworks'"
            [linkTitle]="'Front-end technology'"></giga-page-link>

          <giga-page-link
            class="mt-10"
            [href]="'#portfolio'"
            [pillTitle]="'Portfolio'"
            [linkTitle]="'See Examples'"></giga-page-link>
        </div>
        <div
          class="mx-auto mt-16 flex max-w-2xl sm:mt-24 lg:ml-10 lg:mt-0 lg:mr-0 lg:max-w-none lg:flex-none xl:ml-32">
          <div class="max-w-3xl flex-none sm:max-w-5xl lg:max-w-none">
            <img
              src="assets/webstorm-ide-night-owl.png"
              alt="App screenshot"
              width="2432"
              height="1442"
              class="w-[76rem] rounded-md bg-white/5 shadow-2xl ring-1 ring-white/10" />
          </div>
        </div>
      </div>
    </div>

    <giga-section-title [id]="'frameworks'">Frameworks</giga-section-title>

    <!-- Logo cloud -->
    <div class="mx-auto mt-8 max-w-7xl px-6 sm:mt-16 lg:px-8">
      <h2 class="text-lg font-normal leading-8 text-white">Front-end frameworks experience.</h2>
      <div
        class="z-50 mx-auto mt-10 grid max-w-lg grid-cols-2 items-center gap-x-8 gap-y-10 sm:max-w-lg sm:grid-cols-3 sm:gap-x-10 lg:mx-0 lg:max-w-none lg:grid-cols-5">
        @for (f of portfolioFrameworks; track f.id) {
          <giga-logo-item [href]="f.href" [src]="f.src">
            {{ f.title }}
          </giga-logo-item>
        }
      </div>
    </div>

    <giga-section-title [id]="'portfolio'">Portfolio</giga-section-title>

    <giga-portfolio-item siteLink="https://evolving-cognition.com/home" siteLinkLabel="See Site">
      <giga-portfolio-title>Cross-platform App</giga-portfolio-title>
      <giga-portfolio-image>
        <svg
          viewBox="0 0 366 729"
          role="img"
          class="mx-auto w-[22.875rem] max-w-full drop-shadow-xl">
          <title>App screenshot</title>
          <defs>
            <clipPath id="2ade4387-9c63-4fc4-b754-10e687a0d332">
              <rect width="316" height="684" rx="36" />
            </clipPath>
          </defs>
          <path
            fill="#4B5563"
            d="M363.315 64.213C363.315 22.99 341.312 1 300.092 1H66.751C25.53 1 3.528 22.99 3.528 64.213v44.68l-.857.143A2 2 0 0 0 1 111.009v24.611a2 2 0 0 0 1.671 1.973l.95.158a2.26 2.26 0 0 1-.093.236v26.173c.212.1.398.296.541.643l-1.398.233A2 2 0 0 0 1 167.009v47.611a2 2 0 0 0 1.671 1.973l1.368.228c-.139.319-.314.533-.511.653v16.637c.221.104.414.313.56.689l-1.417.236A2 2 0 0 0 1 237.009v47.611a2 2 0 0 0 1.671 1.973l1.347.225c-.135.294-.302.493-.49.607v377.681c0 41.213 22 63.208 63.223 63.208h95.074c.947-.504 2.717-.843 4.745-.843l.141.001h.194l.086-.001 33.704.005c1.849.043 3.442.37 4.323.838h95.074c41.222 0 63.223-21.999 63.223-63.212v-394.63c-.259-.275-.48-.796-.63-1.47l-.011-.133 1.655-.276A2 2 0 0 0 366 266.62v-77.611a2 2 0 0 0-1.671-1.973l-1.712-.285c.148-.839.396-1.491.698-1.811V64.213Z" />
          <path
            fill="#343E4E"
            d="M16 59c0-23.748 19.252-43 43-43h246c23.748 0 43 19.252 43 43v615c0 23.196-18.804 42-42 42H58c-23.196 0-42-18.804-42-42V59Z" />
          <foreignObject
            width="316"
            height="684"
            transform="translate(24 24)"
            clip-path="url(#2ade4387-9c63-4fc4-b754-10e687a0d332)">
            <img src="assets/portfolio/ec-ios-diaplay-6-7-1.png" alt="" />
          </foreignObject>
        </svg>
      </giga-portfolio-image>
      <giga-portfolio-description>
        <ul class="mb-4 pl-4 list-disc">
          <li>The Core app is built with Angular, TailwindCSS, Angular Material, NgRx, D3.</li>
          <li>Custom components are built for special features.</li>
          <li>CapacitorJS is used to deploy to Google Play and App Store.</li>
          <li>Firebase with Firestore is used for live data updates and authentication.</li>
          <li>Google Cloud Functions ( via Firebase ) coded with node.js support heavy tasks.</li>
        </ul>
      </giga-portfolio-description>
    </giga-portfolio-item>

    <giga-portfolio-item [image]="'assets/portfolio/mixer-controls.png'">
      <giga-portfolio-title>Music collaboration app</giga-portfolio-title>
      <giga-portfolio-description>
        <p class="mb-4">
          This project as built with Angular, NgRx, D3, and a lot of custom components and services.
          The performance was so critical that a custom dynamic state management system was built to
          for some data flow options, and NgRx was used for more static state management control.
        </p>
        <p class="mb-4">
          Electronjs was used to create a desktop app for Mac and Windows deployment.
        </p>
      </giga-portfolio-description>
    </giga-portfolio-item>

    <giga-portfolio-item [image]="'assets/portfolio/vue-to-angular-migration.png'">
      <giga-portfolio-title>Migration from Vue to Angular Project</giga-portfolio-title>
      <giga-portfolio-description>
        <p class="mb-4">
          The project was a migration of multiple Vue apps to an Angular Nx monorepo. The effort was
          planned in phases:
        </p>
        <ul class="list-none list-inside mb-4 pl-4">
          <li><span class="font-bold">Phase 1</span>: Create a new Angular Nx monorepo</li>
          <li><span class="font-bold">Phase 2</span>: Migrate the first Vue app to Angular</li>
          <li><span class="font-bold">Phase 3</span>: Performance Optimization</li>
          <li>
            <span class="font-bold">Phase 4</span>: Create Reusable Components in a shared design
            library
          </li>
        </ul>
        <p class="mb-4">
          To keep the mental models manageable, an architectural strategy was to migrate vue to
          angular to a near one-to-one mapping. Keeping the same component file structure. For state
          management, NgRX was used for global state, and NgRx Component Store was used for
          component state. If Angular Signals were available at the time, the mapping would have
          been more straightforward.
        </p>
        <p class="mb-4">
          Above is the performance results of the main pages up to Phase 2. The performance will be
          much more improved after using Effects in NgRX to preemptively load data upon
          authentication.
        </p>
      </giga-portfolio-description>
    </giga-portfolio-item>

    <!-- Feature section -->
    <!--    <giga-cross-platform-->
    <!--      *ngIf="false"-->
    <!--      class="mx-auto mt-32 max-w-7xl px-6 sm:mt-56 lg:px-8"></giga-cross-platform>-->

    <!-- Feature section -->
    <!--    <giga-live-data *ngIf="false" class="mt-32 sm:mt-56"></giga-live-data>-->

    <!-- CTA section -->
    <!--    <giga-cta></giga-cta>-->
  </main>

  <div class="h-12"></div>
  <!-- Footer -->
  <giga-footer *ngIf="false"></giga-footer>
</div>
