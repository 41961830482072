import {Component, input, InputSignal} from '@angular/core';
import {CommonModule} from '@angular/common';
import {RouterLink} from '@angular/router';

@Component({
  selector: 'giga-page-link',
  standalone: true,
  imports: [CommonModule, RouterLink],
  templateUrl: './page-link.component.html',
  styleUrl: './page-link.component.scss'
})
export class PageLinkComponent {
  pillTitle = input('');
  linkTitle = input('');
  href = input(<string | null>null);
  route = input('');
}
