import {Component, ViewEncapsulation} from '@angular/core';
import {CommonModule} from '@angular/common';

@Component({
  selector: 'giga-cross-platform',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './cross-platform.component.html',
  styleUrl: './cross-platform.component.scss',
  encapsulation: ViewEncapsulation.None,
  host: {
    class: 'giga-cross-platform'
  }
})
export class CrossPlatformComponent {}
