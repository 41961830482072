import {NgPatNavItem} from '@ngpat/utils';

export const app_header_links: NgPatNavItem[] = [
  {
    url: '/',
    label: 'Home'
  },
  // {
  //   url: '/consultation',
  //   label: 'Consultation'
  // },
  // {
  //   url: '/prices',
  //   label: 'Prices'
  // },
  {
    url: '/skills',
    label: 'Skills'
  },
  {
    url: '/architecture',
    label: 'Architecture'
  },
  {
    url: '/contact',
    label: 'Contact'
  }
];

export const app_router_links: NgPatNavItem[] = [
  // {
  //   url: '/consultation/services',
  //   label: 'Services'
  // },
  // {
  //   url: '/consultation/prices',
  //   label: 'Prices'
  // },
  // {
  //   url: '/consultation/buy',
  //   label: 'Buy'
  // }
];

export const app_header_menu_links: NgPatNavItem[] = [
  {
    url: '/',
    label: 'Home'
  },
  ...app_router_links,
  {
    url: '/contact',
    label: 'Contact'
  }
];
