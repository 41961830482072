import {CommonModule} from '@angular/common';
import {Component, ViewEncapsulation} from '@angular/core';

@Component({
  selector: 'giga-cta',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './cta.component.html',
  styleUrl: './cta.component.scss',
  encapsulation: ViewEncapsulation.None,
  host: {
    class: 'giga-cta'
  }
})
export class CtaComponent {}
