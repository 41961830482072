<a
  [href]="href()"
  target="_blank"
  class="col-span-2 w-full lg:col-span-1 flex flex-row justify-start items-center">
  @if (src()) {
    <img class="max-h-12 object-contain" [attr.src]="src()" />
  }
  <span class="ml-2 underline underline-offset-4">
    <ng-content></ng-content>
  </span>
</a>
