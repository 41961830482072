/**
 * Evolving Cognition Service Account Key - DEVELOPMENT
 */
export const firebaseEvolvingCognitionServiceAccount = {
  type: 'service_account',
  project_id: 'evolving-cognition-dev',
  private_key_id: '8a5d95afc366ad220db2c4118b557df635e5642a',
  private_key:
    '-----BEGIN PRIVATE KEY-----\nMIIEvgIBADANBgkqhkiG9w0BAQEFAASCBKgwggSkAgEAAoIBAQC/WLR1CUsDvKlc\nl00hKHkDjco8MBdSiZYWUux5xlhkXuSqpsjGmb+v4XFmYhVHV0KFOPOC9YNB/39P\nmPr7XEhyVSLlnMM3P6fN3YH5/jV3iYpkqKpvKkaWugaRkzY/n2bh55/zsSku1AcO\n3Yg3sn2dbKPT06SkKi6MqyQE8mPc9NNyNd5xQLBVZbcubvfDso8Jp0nil8F1bO1f\nu8BAvKe7ZLbPwSr7KezXOpgs7Xsru7ymHcwmTr8M1Y+2vvsY2SrTWnWC+ae9NWDJ\nkmC5vb1B7xizJBzyLg8MSto33Cq7cg1mG6GvQ7n9BoJPhploQumLFsNNbw494jOF\n9iYDSlH/AgMBAAECggEABGs+g6NqzZPKT36ZA+aE4O2h2YD80OWnhy10E53ehbbh\nTgWbBmcfk6a/sS8bsbGUsjHuAby8wsXtQT/Vr/9iFu3bZ/UWVwzLycfPTCAwSOnu\nvcNdaELQLYPvSeZd7ts2C4qHWZ/iRRgVb6TzE+I133BWWjp9h2MH/yymoMyePMiK\nbX6/Jri4N4H4E6u7O3YEpjDl78o1lj/X7lQc+BS8m6RSbbdS37Q7h3WdtpzpjwzZ\nmc3+lQzxkLNxLIgQFsr8cfTuSXnYRVy0E4u0eBeXakOIUFu3ZmcioJtfOim3b1Ii\nFeh1V4xGQsn0jf32wEtNcHM7hVorELmHQqLyi1T4OQKBgQDjuT+m53z8D4YLBNzc\nq+0nC0PtZUNH89tFCUKYKSsN2Szi8ToFCJ1yZXmLJzKa4MbeNBv5QLUYU2FrFKau\nj/D8yxVYahFEze01gX7E0X1pNeeMXr0lcMmCfMz+S11+OogMVV0MACsaVe4Fi1BW\n2Bf2GWgQemKuEnwImDnQ+Ow+9wKBgQDXGx7fFL0IjY865YXGUakj9liDswI07NF6\no8PIk8U+JLQjBboLpAw6OLpXJ0istclfj5dd12NAc8PUzS/KJh0qkxLrzrc1ULah\neKh0G6kyU8Fst9qeLVJyDGelB9I28SE+4XYQZar3Qj/BXQffke9Jv+H0RNm7Gky2\nyH0gTgXbOQKBgQDWjexF4eblSe7+JMe+9hGL7tZCLl1UxpI6Gj5vRKJ2+r6K18LV\niUdmx+rbS8QnHk7gfnI4xlmZoL2xeRSOdboVQ3ZKBi1dCTRMbES3RbWGUi5mF8DY\nLMr/MXymvvVIf/c8v5TaQLJbrsiC2r6IBDOXQFrG47hjz7CjHGGk3VMhAwKBgQCY\nXQB3+UOQjFy41hz0haEt9O9rkd9tR5V1rgwexfS/eNYTvJm0ZyW2E7qBwzSqCTWz\nN417Dfaij51V3WUNZgPgxT5wwwJohFxocYO4gmNNX6AxduUmh2nPmNPNRIX1HsVy\nudab9Let3kI0n8XU6ZS6A210TVSAQDe4fLiMqldhmQKBgDNMdqEbBj4Il7ivGWSw\nVSkjHmdoWOg7oDqAHOQtonDh2OvVwu8UYlcGfdfiJkREWAKvxZna9UXuKao1lSIk\nJssWf/fmZ/yhhgD1oLxAbB2Z/TCgge1eT8F50y+2juYGRBJ0TK0uGMlBP1SYSWMd\nUhJXIJSCAPFP70vICm//+CsP\n-----END PRIVATE KEY-----\n',
  client_email: 'firebase-adminsdk-hherc@evolving-cognition-dev.iam.gserviceaccount.com',
  client_id: '103870389062556290701',
  auth_uri: 'https://accounts.google.com/o/oauth2/auth',
  token_uri: 'https://oauth2.googleapis.com/token',
  auth_provider_x509_cert_url: 'https://www.googleapis.com/oauth2/v1/certs',
  client_x509_cert_url:
    'https://www.googleapis.com/robot/v1/metadata/x509/firebase-adminsdk-hherc%40evolving-cognition-dev.iam.gserviceaccount.com',
  universe_domain: 'googleapis.com'
};

/**
 * Evolving Cognition Firebase Config - Authentication - DEVELOPMENT
 */
export const firebaseEvolvingCognitionAuth = {
  apiKey: 'AIzaSyBwoLkJRuNa5R-3M60M5lmfSVmp2-Js7tg',
  authDomain: 'evolving-cognition-dev.firebaseapp.com',
  projectId: 'evolving-cognition-dev',
  storageBucket: 'evolving-cognition-dev.appspot.com',
  messagingSenderId: '197608190685',
  appId: '1:197608190685:web:bc2b07bd9b3d4e639f6134',
  measurementId: 'G-NBNNGZDFQ4'
};

/**
 * Evolving Cognition Firebase Config - App - DEVELOPMENT
 */
export const firebaseEvolvingCognitionApp = {
  apiKey: 'AIzaSyBwoLkJRuNa5R-3M60M5lmfSVmp2-Js7tg',
  authDomain: 'evolving-cognition-dev.firebaseapp.com',
  projectId: 'evolving-cognition-dev',
  storageBucket: 'evolving-cognition-dev.appspot.com',
  messagingSenderId: '197608190685',
  appId: '1:197608190685:web:e5ddbf78d1a5ac2d9f6134',
  measurementId: 'G-KN6WTB9F12'
};

/**
 * Evolving Cognition Firebase Config - Marketing - DEVELOPMENT
 */
export const firebaseEvolvingCognitionMarketing = {
  apiKey: 'AIzaSyBwoLkJRuNa5R-3M60M5lmfSVmp2-Js7tg',
  authDomain: 'evolving-cognition-dev.firebaseapp.com',
  projectId: 'evolving-cognition-dev',
  storageBucket: 'evolving-cognition-dev.appspot.com',
  messagingSenderId: '197608190685',
  appId: '1:197608190685:web:a420e64f98d32d369f6134',
  measurementId: 'G-7ZF75CVGSL'
};

// gigasoft-svc-dev
export const firebaseConfigGigaSoftwareIO = {
  apiKey: 'AIzaSyAKePvdW1SFrYdg3xQfJXE6nETfSAd494Q',
  authDomain: 'gigasoft-svc-dev.firebaseapp.com',
  projectId: 'gigasoft-svc-dev',
  storageBucket: 'gigasoft-svc-dev.appspot.com',
  messagingSenderId: '892890842461',
  appId: '1:892890842461:web:a17db86a89845a5e7a94b4',
  measurementId: 'G-E4NCL9CLLR'
};

// myhomeaway-auth-dev.estate
export const myHomeAwayAuth = {
  apiKey: 'AIzaSyBFzueBimBaKBT2sjpLGtzemXTjID5LbOw',
  authDomain: 'myhomeawayestate-dev.firebaseapp.com',
  projectId: 'myhomeawayestate-dev',
  storageBucket: 'myhomeawayestate-dev.appspot.com',
  messagingSenderId: '203317037011',
  appId: '1:203317037011:web:4520fe1492f6ef1b981126',
  measurementId: 'G-VTBRFMFY52'
};

export const myHomeAwaySite = {
  apiKey: 'AIzaSyBFzueBimBaKBT2sjpLGtzemXTjID5LbOw',
  authDomain: 'myhomeawayestate-dev.firebaseapp.com',
  projectId: 'myhomeawayestate-dev',
  storageBucket: 'myhomeawayestate-dev.appspot.com',
  messagingSenderId: '203317037011',
  appId: '1:203317037011:web:88bfa251fed38d3a981126',
  measurementId: 'G-3XRZFLNFYT'
};

export const firebaseAuthToken = '1//0fNRRqJgA4rGICgYIARAAGA8SNwF-L9IrUEdHJgFnb5pGmc1DJN27Nm2_n8_q1EYWBC8HGoTgxDRC3s-JSvlg4hfsd5I-O7UxLyI';
