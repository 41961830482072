import {Component, input} from '@angular/core';

@Component({
  selector: 'giga-portfolio-item',
  templateUrl: './portfolio-item.component.html',
  styleUrl: './portfolio-item.component.scss'
})
export class PortfolioItemComponent {
  image = input(<string | null>null);
  siteLink = input(<string | null>null);
  siteLinkLabel = input(<string | null>null);
}
