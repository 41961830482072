import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {MatIcon} from '@angular/material/icon';
import {GigaPortfolioDescriptionDirective} from './portfolio-description';
import {GigaPortfolioImageDirective} from './portfolio-image';
import {PortfolioItemComponent} from './portfolio-item.component';
import {GigaPortfolioTitleDirective} from './portfolio-title';

@NgModule({
  declarations: [
    GigaPortfolioDescriptionDirective,
    GigaPortfolioImageDirective,
    GigaPortfolioTitleDirective,
    PortfolioItemComponent
  ],
  imports: [CommonModule, MatIcon],
  exports: [
    GigaPortfolioDescriptionDirective,
    GigaPortfolioImageDirective,
    GigaPortfolioTitleDirective,
    PortfolioItemComponent
  ]
})
export class PortfolioModule {}
