import {provideHttpClient} from '@angular/common/http';
import {ApplicationConfig, enableProdMode, importProvidersFrom} from '@angular/core';
import {MatDialogModule} from '@angular/material/dialog';
import {provideAnimations} from '@angular/platform-browser/animations';
import {provideRouter, withEnabledBlockingInitialNavigation} from '@angular/router';
import {createNgPatFirebaseAppInstance, NG_PAT_FIREBASE_INSTANCE} from '@ngpat/firebase';
import {WINDOW_PROVIDERS} from '@ngpat/utils';
import {provideStore} from '@ngrx/store';
import {provideStoreDevtools} from '@ngrx/store-devtools';
import {environment} from '../environments/environment';
import {appRoutes} from './app.routes';
import {checkoutReducer, checkoutsFeatureKey, initialCheckoutState} from './+checkout/index';

if (environment.production) {
  enableProdMode();
}

const appInstance = createNgPatFirebaseAppInstance(environment.firebaseConfigParams);

export const appConfig: ApplicationConfig = {
  providers: [
    provideRouter(appRoutes, withEnabledBlockingInitialNavigation()),
    provideAnimations(),
    {
      provide: NG_PAT_FIREBASE_INSTANCE,
      useValue: appInstance
    },
    // {
    //   provide: NG_PAT_PRESENCE_CONFIG,
    //   useValue: {timerInMinutes: 5}
    // },
    // {
    //   provide: FIREBASE_AUTH_CONFIG,
    //   useValue: <FirebaseAuthConfig>{
    //     // tosUrl: true,
    //     signInFlow: 'redirect',
    //     signInOptions: [
    //       EmailAuthProvider.PROVIDER_ID,
    //       {
    //         provider: GoogleAuthProvider.PROVIDER_ID,
    //         customParameters: {
    //           // https://firebase.google.com/docs/auth/web/firebaseui
    //           // Forces account selection even when one account
    //           // is available.
    //           prompt: 'select_account'
    //         }
    //       }
    //     ]
    //   }
    // },
    ...WINDOW_PROVIDERS,
    importProvidersFrom(MatDialogModule),
    provideHttpClient(),
    provideStore(
      {
        // ...NG_PAT_FIREBASE_ROOT_REDUCERS,
        // ...NG_PAT_STRIPE_REDUCERS,
        [checkoutsFeatureKey]: checkoutReducer
      },
      {
        initialState: {
          // ...NG_PAT_FIREBASE_ROOT_STATE_INITIALIZERS,
          // ...NG_PAT_STRIPE_INITIALIZERS,
          [checkoutsFeatureKey]: initialCheckoutState
        },
        runtimeChecks: {
          strictStateImmutability: true,
          strictActionImmutability: true,
          strictStateSerializability: true,
          strictActionSerializability: true,
          strictActionWithinNgZone: true,
          strictActionTypeUniqueness: true
        }
      }
    ),
    // provideEffects([...NG_PAT_FIREBASE_ROOT_EFFECTS, ...NG_PAT_STRIPE_EFFECTS, CheckoutEffects]),
    provideStoreDevtools({
      maxAge: 25,
      logOnly: !environment.production
    })
    // {
    //   provide: NG_PAT_LOCAL_STORAGE_CONFIGURATION,
    //   useValue: {
    //     enableEncryption: true,
    //     encryptionKey: 'gigasoftware-asdlkfjas',
    //     excludeKeys: []
    //   }
    // }
  ]
};
