import {
  addDatabasePaths,
  addRemoteConfigParams,
  createDefaultFirebaseConfig,
  ENV_SERVER_ENUM
} from '@ngpat/firebase';
import {firebaseConfigGigaSoftwareIO} from '@secrets';
import {GigaSoftEnv} from './environment.model';

const firebaseConfigParams = createDefaultFirebaseConfig({
  firebase: firebaseConfigGigaSoftwareIO,
  env: ENV_SERVER_ENUM.DEV,
  appName: 'gigasoftware-io',
  remoteConfigParams: addRemoteConfigParams(),
  databasePaths: addDatabasePaths(),
  appNameInFirebaseConfig: false
});

export const environment: GigaSoftEnv = {
  env: ENV_SERVER_ENUM.DEV,
  production: false,
  firebaseConfigParams: firebaseConfigParams
};
