import {CommonModule} from '@angular/common';
import {ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {MatButtonModule} from '@angular/material/button';
import {MatIconModule} from '@angular/material/icon';
import {MatMenuModule} from '@angular/material/menu';
import {MatTabsModule} from '@angular/material/tabs';
import {RouterLink} from '@angular/router';
import {
  ngPatLogout,
  NgPatUserAccount,
  selectNgPatIsLoggedIn,
  selectNgPatUserAccount,
  NgPatEntityStore
} from '@ngpat/store';
import {NgPatNavItem, NgPatRouterFacadeService} from '@ngpat/utils';
import {LetDirective, PushPipe} from '@ngrx/component';
import {Store} from '@ngrx/store';
import {Observable, of, switchMap} from 'rxjs';
import {map} from 'rxjs/operators';
import {
  app_header_links,
  app_header_menu_links,
  app_router_links
} from '../../pages/app_router_links';
import {LogoNameComponent} from '../logo-name/logo-name.component';
import {SocialComponent} from '../social/social.component';

@Component({
  selector: 'giga-header',
  standalone: true,
  imports: [
    CommonModule,
    LogoNameComponent,
    SocialComponent,
    MatTabsModule,
    RouterLink,
    MatMenuModule,
    LetDirective,
    MatButtonModule,
    MatIconModule,
    PushPipe
  ],
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class HeaderComponent implements OnInit {
  links: NgPatNavItem[] = [...app_header_links];

  headerMenuLinks: NgPatNavItem[] = [...app_header_menu_links];

  private routeCache: NgPatEntityStore<NgPatNavItem> = new NgPatEntityStore<NgPatNavItem>(
    'url'
  );

  activeLink: NgPatNavItem = this.links[0];
  //
  // isLoggedIn$: Observable<boolean> = this.store.select(selectNgPatIsLoggedIn);
  // userName$: Observable<string | null> = this.store.select(selectNgPatUserAccount).pipe(
  //   map((a: NgPatUserAccount): string | null => {
  //     if (a.username) {
  //       return a.username;
  //     } else if (a.displayName) {
  //       return a.displayName;
  //     } else {
  //       return a.email;
  //     }
  //   })
  // );

  constructor(
    private routerFacadeService: NgPatRouterFacadeService,
    private cd: ChangeDetectorRef
  ) {}

  // logout() {
  //   this.store.dispatch(ngPatLogout());
  // }

  ngOnInit() {
    this.routeCache.addMany([...app_header_links, ...app_router_links]);

    this.routerFacadeService
      .getRouteEnd$([...app_header_links, ...app_router_links])
      .pipe(
        switchMap((end: NgPatNavItem | null) => {
          if (end !== null) {
            return this.routeCache.selectItemById$(end.url).pipe(
              switchMap((item: NgPatNavItem | undefined) => {
                if (item) {
                  const _url = item.url[0] === '/' ? item.url.substring(1) : item.url;
                  const firstSegment = _url.split('/')[0];
                  return this.routeCache.selectItemById$(`/${firstSegment}`);
                }

                return of(end);
              })
            );
          }

          return of(end);
        })
      )
      .subscribe((end: NgPatNavItem | null | undefined) => {
        if (end !== null && end !== undefined) {
          this.activeLink = end;
          this.cd.detectChanges();
        }
      });
  }
}
