import {CommonModule} from '@angular/common';
import {Component, OnInit} from '@angular/core';
import {MatIconModule} from '@angular/material/icon';
import {RouterLink} from '@angular/router';
import {NgPatFirestoreService} from '@ngpat/firebase';
import {FooterComponent} from '../../components/footer/footer.component';
import {LogoNameComponent} from '../../components/logo-name/logo-name.component';
import {SocialComponent} from '../../components/social/social.component';
import {CtaComponent} from './cta/cta.component';
import {CrossPlatformComponent} from './features/cross-platform/cross-platform.component';
import {LiveDataComponent} from './features/live-data/live-data.component';
import {LogoItemComponent} from './logo-item/logo-item.component';
import {PageLinkComponent} from './page-link/page-link.component';
import {PortfolioModule} from './portfolio/portfolio.module';
import {SectionTitleComponent} from './title/section-title.component';

interface PortfolioFramework {
  id: string;
  href: string;
  src: string;
  title: string;
}

@Component({
  selector: 'giga-home',
  standalone: true,
  imports: [
    CommonModule,
    MatIconModule,
    RouterLink,
    FooterComponent,
    LogoNameComponent,
    SocialComponent,
    CtaComponent,
    CrossPlatformComponent,
    LiveDataComponent,
    PortfolioModule,
    LogoItemComponent,
    SectionTitleComponent,
    PageLinkComponent
  ],
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
  portfolioFrameworks: PortfolioFramework[] = [
    {
      id: 'nx',
      href: 'https://nx.dev/',
      src: 'assets/front-end-tech/Nx_FFF.svg',
      title: 'Nx Monorepo'
    },
    {
      id: 'angular',
      href: 'https://angular.dev',
      src: 'assets/front-end-tech/angular_gradient.png',
      title: ' Angular v18'
    },
    {
      id: 'tailwindcss',
      href: 'https://tailwindcss.com/',
      src: 'assets/front-end-tech/tailwind-logo.svg',
      title: 'TailwindCSS'
    },
    {
      id: 'ngrx',
      href: 'https://ngrx.io/docs',
      src: 'assets/front-end-tech/ngrx.svg',
      title: 'NgRx'
    },
    {
      id: 'nodejs',
      href: 'https://nodejs.org',
      src: 'assets/front-end-tech/node-jsIconGreen.svg',
      title: 'Node.js'
    },
    {
      id: 'd3',
      href: 'https://d3js.org/',
      src: 'assets/front-end-tech/d3.svg',
      title: 'Charts'
    },
    {
      id: 'capacitor',
      href: 'https://capacitorjs.com/',
      src: 'assets/front-end-tech/capacitor-logo.svg',
      title: 'Capacitorjs'
    },

    {
      id: 'electron',
      href: 'https://www.electronjs.org/',
      src: 'assets/front-end-tech/electron.svg',
      title: 'Electronjs'
    },

    {
      id: 'storybook',
      href: 'https://storybook.js.org/',
      src: 'assets/front-end-tech/storybook.svg',
      title: 'Storybook'
    },
    {
      id: 'firebase',
      href: 'https://firebase.google.com/',
      src: 'assets/front-end-tech/firebase.png',
      title: 'Firebase'
    }
  ];

  constructor(private firestore: NgPatFirestoreService) {}

  // portfolioOptimizationImage =
  //   'https://tailwindui.com/img/component-images/dark-project-app-screenshot.png';

  ngOnInit() {
    this.firestore.logEvent('home_page');
  }
}
