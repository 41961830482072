import {Route} from '@angular/router';
import {HomeComponent} from './pages/home/home.component';

export const appRoutes: Route[] = [
  {
    path: '',
    component: HomeComponent
  },
  {
    path: 'skills',
    loadComponent: () => import('./pages/skills/skills.component').then(mod => mod.SkillsComponent)
  },
  {
    path: 'architecture',
    loadComponent: () =>
      import('./pages/architecture/architecture.component').then(mod => mod.ArchitectureComponent)
  },
  // {
  //   path: 'consultation',
  //   redirectTo: 'consultation/services',
  //   pathMatch: 'full'
  // },
  // {
  //   path: 'consultation',
  //   loadChildren: () =>
  //     import('./pages/consultation/consultation.route').then(
  //       mod => mod.CONSULTATION_ROUTE
  //     )
  // },
  {
    path: 'contact',
    loadComponent: () =>
      import('./pages/contact/contact.component').then(mod => mod.ContactComponent)
  },
  // {
  //   path: 'prices',
  //   loadComponent: () =>
  //     import('./pages/consultation/prices/prices.component').then(
  //       mod => mod.PricesComponent
  //     )
  // },
  // {
  //   path: 'checkout',
  //   canActivate: [ngPatCanActivateLoggedIn],
  //   loadComponent: () =>
  //     import('./pages/consultation/checkout/checkout.component').then(
  //       mod => mod.CheckoutComponent
  //     )
  // },

  {path: '**', redirectTo: '', pathMatch: 'full'}
];
