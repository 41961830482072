<a
  href="https://www.linkedin.com/in/jerryorta/"
  target="_blank"
  class="text-gray-500 hover:text-gray-400">
  <span class="sr-only">LinkedIn</span>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_110_23)">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M1.77375 0C0.794437 0 0 0.77025 0 1.71919V22.2814C0 23.2304 0.794531 24 1.77375 24H22.2262C23.2059 24 24 23.2303 24 22.2811V1.71919C24 0.77025 23.2059 0 22.2262 0H1.77375ZM7.29317 9.28002V20.0849H3.7018V9.28002H7.29317ZM7.52989 5.93849C7.52989 6.97536 6.75036 7.80505 5.49824 7.80505L5.47471 7.80496C4.26936 7.80496 3.49011 6.97527 3.49011 5.9384C3.49011 4.87799 4.29289 4.07146 5.52205 4.07146C6.75036 4.07146 7.50646 4.87799 7.52989 5.93849ZM12.8721 20.0849H9.28105C9.28105 20.0849 9.32811 10.294 9.28124 9.2804H12.8724V10.8098C13.3498 10.0737 14.2042 9.02671 16.1091 9.02671C18.4719 9.02671 20.2434 10.5711 20.2434 13.8897V20.0849H16.6524V14.3052C16.6524 12.8526 16.1324 11.8618 14.8332 11.8618C13.8409 11.8618 13.25 12.53 12.9905 13.1754C12.8956 13.4058 12.8721 13.7291 12.8721 14.0516V20.0849Z"
        fill="currentColor" />
    </g>
    <defs>
      <clipPath id="clip0_110_23">
        <rect width="24" height="24" fill="currentColor" />
      </clipPath>
    </defs>
  </svg>
</a>

<a
  href="https://github.com/orgs/GiGaSoftwareDevelopment/repositories"
  target="_blank"
  class="text-gray-500 hover:text-gray-400">
  <span class="sr-only">GitHub</span>
  <svg
    class="h-6 w-6"
    fill="currentColor"
    viewBox="0 0 24 24"
    aria-hidden="true">
    <path
      fill-rule="evenodd"
      d="M12 2C6.477 2 2 6.484 2 12.017c0 4.425 2.865 8.18 6.839 9.504.5.092.682-.217.682-.483 0-.237-.008-.868-.013-1.703-2.782.605-3.369-1.343-3.369-1.343-.454-1.158-1.11-1.466-1.11-1.466-.908-.62.069-.608.069-.608 1.003.07 1.531 1.032 1.531 1.032.892 1.53 2.341 1.088 2.91.832.092-.647.35-1.088.636-1.338-2.22-.253-4.555-1.113-4.555-4.951 0-1.093.39-1.988 1.029-2.688-.103-.253-.446-1.272.098-2.65 0 0 .84-.27 2.75 1.026A9.564 9.564 0 0112 6.844c.85.004 1.705.115 2.504.337 1.909-1.296 2.747-1.027 2.747-1.027.546 1.379.202 2.398.1 2.651.64.7 1.028 1.595 1.028 2.688 0 3.848-2.339 4.695-4.566 4.943.359.309.678.92.678 1.855 0 1.338-.012 2.419-.012 2.747 0 .268.18.58.688.482A10.019 10.019 0 0022 12.017C22 6.484 17.522 2 12 2z"
      clip-rule="evenodd" />
  </svg>
</a>
