import {RouterModule} from '@angular/router';
import {Component} from '@angular/core';
import {HeaderComponent} from './components/header/header.component';
import {MatSidenavModule} from '@angular/material/sidenav';

@Component({
  standalone: true,
  imports: [RouterModule, HeaderComponent, MatSidenavModule],
  selector: 'giga-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  constructor() {}
}
