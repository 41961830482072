import {Component, ViewEncapsulation} from '@angular/core';
import {CommonModule} from '@angular/common';

@Component({
  selector: 'giga-live-data',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './live-data.component.html',
  styleUrl: './live-data.component.scss',
  encapsulation: ViewEncapsulation.None,
  host: {
    class: 'giga-live-data'
  }
})
export class LiveDataComponent {}
