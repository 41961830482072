<header
  class="border-b h-14 border-white/10 w-full flex flex-row justify-start items-center mat-app-background pt-2.5 pr-5 pl-5">
  <giga-logo-name [height]="26"></giga-logo-name>
  <div class="grow"></div>

  <div class="hidden lg:flex">
    <nav mat-tab-nav-bar [tabPanel]="tabPanel">
      <a
        mat-tab-link
        *ngFor="let link of links"
        (click)="activeLink = link"
        [routerLink]="link.url"
        [active]="activeLink.url === link.url">
        {{ link.label }}
      </a>
    </nav>
    <mat-tab-nav-panel #tabPanel></mat-tab-nav-panel>
  </div>
  <div class="grow"></div>
  <giga-social class="hidden lg:flex"></giga-social>
  <div class="hidden lg:block ml-2">
    <!--    <ng-container *ngrxLet="isLoggedIn$; let isLoggedIn">-->
    <!--      <button-->
    <!--        *ngIf="!isLoggedIn"-->
    <!--        mat-icon-button-->
    <!--        [matMenuTriggerFor]="personMenu">-->
    <!--        <mat-icon>person</mat-icon>-->
    <!--      </button>-->

    <!--      <button *ngIf="isLoggedIn" mat-button [matMenuTriggerFor]="personMenu">-->
    <!--        {{ userName$ | ngrxPush }}-->
    <!--      </button>-->
    <!--    </ng-container>-->
    <!--    <mat-menu #personMenu="matMenu">-->
    <!--      <ng-container *ngrxLet="isLoggedIn$; let isLoggedIn">-->
    <!--        <a *ngIf="!isLoggedIn" mat-menu-item [routerLink]="'/login'"> Login </a>-->
    <!--        <button *ngIf="isLoggedIn" mat-menu-item (click)="logout()">-->
    <!--          Logout-->
    <!--        </button>-->
    <!--      </ng-container>-->
    <!--    </mat-menu>-->
  </div>
  <div class="flex lg:hidden">
    <button
      [matMenuTriggerFor]="menu"
      type="button"
      class="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-gray-700">
      <span class="sr-only">Open main menu</span>
      <svg
        class="h-6 w-6"
        fill="none"
        viewBox="0 0 24 24"
        stroke-width="1.5"
        stroke="currentColor"
        aria-hidden="true">
        <path
          stroke-linecap="round"
          stroke-linejoin="round"
          d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5" />
      </svg>
    </button>
    <mat-menu #menu="matMenu">
      <a mat-menu-item *ngFor="let link of headerMenuLinks" [routerLink]="link.url">
        {{ link.label }}
      </a>
      <a mat-menu-item href="https://www.linkedin.com/in/jerryorta/" target="_blank"> LinkedIn </a>
      <a
        mat-menu-item
        href="https://github.com/orgs/GiGaSoftwareDevelopment/repositories"
        target="_blank">
        GitHub
      </a>
      <!--      <ng-container *ngrxLet="isLoggedIn$; let isLoggedIn">-->
      <!--        <a *ngIf="!isLoggedIn" mat-menu-item [routerLink]="'/login'"> Login </a>-->
      <!--        <button *ngIf="isLoggedIn" mat-menu-item (click)="logout()">-->
      <!--          Logout-->
      <!--        </button>-->
      <!--      </ng-container>-->
    </mat-menu>
  </div>
</header>
