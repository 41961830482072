import {Component, input} from '@angular/core';
import {CommonModule} from '@angular/common';

@Component({
  selector: 'giga-logo-item',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './logo-item.component.html',
  styleUrl: './logo-item.component.scss'
})
export class LogoItemComponent {
  src = input(<string | null>null);
  href = input(<string>'');
}
