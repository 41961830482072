import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { getProportion } from '@ngpat/calculations';
import { RouterLink } from '@angular/router';

@Component({
  selector: 'giga-logo-name',
  standalone: true,
  imports: [CommonModule, RouterLink],
  templateUrl: './logo-name.component.html',
  styleUrls: ['./logo-name.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LogoNameComponent {
  /**
   * Width of original svg
   * @private
   */
  private _baseWidth = 42;

  /**
   * Height of original svg
   * @private
   */
  private _baseHeight = 44;
  width = this._baseWidth;

  private _height = this._baseHeight;
  @Input()
  set height(newHeight: number) {
    this._height = newHeight;
    this.width = getProportion(this._baseHeight, this._baseWidth, newHeight);
  }

  get height() {
    return this._height;
  }
}
